import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/Inter_28pt-Regular.ttf\",\"display\":\"swap\"}],\"variableName\":\"myFont\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/alerts/GlobalAlertModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModeToggle"] */ "/app/src/components/themebutton/theme-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/providers.tsx");
